import { internalApi } from '@/lib/axios';
import { GetAdditionalUserInfoPayload } from '@/pages/api/users/get-additional-info';
import { captureException } from '@sentry/nextjs';
import { useSession } from 'next-auth/react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

interface AdditionalUserInfoContext {
  additinalUserInfo: GetAdditionalUserInfoPayload['user'];
  setAdditionalUserInfo(
    additionalUserInfo: GetAdditionalUserInfoPayload['user'],
  ): void;
}

const AdditionalUserInfoContext = createContext<AdditionalUserInfoContext>(
  {} as AdditionalUserInfoContext,
);

export function AdditionalUserInfoProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [additinalUserInfo, setAdditionalUserInfo] = useState<
    GetAdditionalUserInfoPayload['user']
  >({});

  const { data: sessionData } = useSession();

  useEffect(() => {
    async function fetchAditionalInfo() {
      try {
        const { data } = await internalApi.get(`/users/get-additional-info`);

        setAdditionalUserInfo(data.user);
      } catch (err) {
        captureException(err);
      }
    }

    if (sessionData?.user.email) {
      fetchAditionalInfo();
    }
  }, [sessionData?.user.email]);

  const value = useMemo(
    () => ({ additinalUserInfo, setAdditionalUserInfo }),
    [additinalUserInfo, setAdditionalUserInfo],
  );

  return (
    <AdditionalUserInfoContext.Provider value={value}>
      {children}
    </AdditionalUserInfoContext.Provider>
  );
}

export const useAdditionalUserInfo = () =>
  useContext(AdditionalUserInfoContext);
