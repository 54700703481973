import Script from 'next/script';

declare global {
  interface Window {
    gtag: (
      event: string,
      action: string,
      options?: Record<string, unknown>,
    ) => void;
  }
}

export function createGtagEvent(
  event: string,
  action: string,
  options?: Record<string, unknown>,
) {
  if (process.env.NODE_ENV !== 'production') return;
  window.gtag(event, action, options);
}

export function GoogleAnalyticsScript() {
  return (
    <>
      <Script id="google-analytics" strategy="lazyOnload">
        {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}');
`}
      </Script>

      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID}`}
      />
    </>
  );
}
